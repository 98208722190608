import React from "react";
import Icon, { IconProps } from "./Icon";
import { colors } from "@puzzle/theme";

export default function Bill({ viewBox = "0 0 14 14", size, ...iconProps }: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox} size={size}>
      <path
        d="M10.4998 5.63787e-05H1.49981C1.39975 -0.00595835 1.29955 0.00932042 1.20583 0.0448839C1.11211 0.0804474 1.027 0.135487 0.956123 0.206367C0.885242 0.277248 0.830203 0.362358 0.79464 0.456078C0.759076 0.549798 0.743798 0.649996 0.749812 0.750056V12.0001L2.99981 10.5001L4.49981 12.0001L5.99981 10.5001L7.49981 12.0001L8.99981 10.5001L11.2498 12.0001V0.750056C11.2558 0.649996 11.2405 0.549798 11.205 0.456078C11.1694 0.362358 11.1144 0.277248 11.0435 0.206367C10.9726 0.135487 10.8875 0.0804474 10.7938 0.0448839C10.7001 0.00932042 10.5999 -0.00595835 10.4998 5.63787e-05ZM5.99981 7.50006H2.99981V6.00006H5.99981V7.50006ZM5.99981 4.50006H2.99981V3.00006H5.99981V4.50006ZM8.24981 7.50006C8.10148 7.50006 7.95647 7.45607 7.83313 7.37366C7.7098 7.29125 7.61367 7.17411 7.5569 7.03707C7.50014 6.90002 7.48528 6.74922 7.51422 6.60374C7.54316 6.45825 7.61459 6.32462 7.71948 6.21973C7.82437 6.11484 7.95801 6.04341 8.10349 6.01447C8.24898 5.98553 8.39978 6.00038 8.53683 6.05715C8.67387 6.11391 8.791 6.21004 8.87341 6.33338C8.95583 6.45672 8.99981 6.60172 8.99981 6.75006C8.99981 6.94897 8.92079 7.13973 8.78014 7.28039C8.63949 7.42104 8.44872 7.50006 8.24981 7.50006ZM8.24981 4.50006C8.10148 4.50006 7.95647 4.45607 7.83313 4.37366C7.7098 4.29125 7.61367 4.17411 7.5569 4.03707C7.50014 3.90002 7.48528 3.74922 7.51422 3.60374C7.54316 3.45825 7.61459 3.32462 7.71948 3.21973C7.82437 3.11484 7.95801 3.04341 8.10349 3.01447C8.24898 2.98553 8.39978 3.00038 8.53683 3.05715C8.67387 3.11391 8.791 3.21004 8.87341 3.33338C8.95583 3.45672 8.99981 3.60172 8.99981 3.75006C8.99981 3.94897 8.92079 4.13973 8.78014 4.28039C8.63949 4.42104 8.44872 4.50006 8.24981 4.50006Z"
        fill={colors.white}
      />
    </Icon>
  );
}
