import React, { useCallback, useState } from "react";
import { CustomerFragment } from "graphql/types";
import { DropdownField, DropdownFieldProps } from "../../../transactions/DropdownField";
import { useCustomersLazyQuery } from "components/common/hooks/graphql.generated";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useDebounce } from "use-debounce";
import { makeOptimisticCustomer, useCreateCustomer } from "components/common/hooks/useCustomers";
import { useToasts } from "@puzzle/ui";
import { useApolloClient } from "@apollo/client";
import { CustomerFragmentDoc } from "graphql/fragments/customer.generated";

type CustomerSelectProps = {
  onSelect: (name: CustomerFragment | null) => void;
  value?: CustomerFragment | null;
  canEdit?: boolean;
  fullWidth?: boolean;
  onCreateCustomer?: (name: string) => void;
} & Pick<DropdownFieldProps<CustomerFragment>, "emptyState" | "customTrigger" | "menuCss">;

export const CustomerSelect = ({
  onSelect,
  canEdit = true,
  onCreateCustomer,
  value,
  fullWidth = false,
  ...props
}: CustomerSelectProps) => {
  const client = useApolloClient();
  const [filter, setFilter] = useState("");
  const [debouncedFilter, { isPending }] = useDebounce(filter, 500);
  const { company } = useActiveCompany();
  const { toast } = useToasts();

  const [
    loadCustomers,
    { called: loadCustomersCalled, data: customerListResponse, loading: isQueryActive },
  ] = useCustomersLazyQuery();

  const [_createCustomer] = useCreateCustomer({
    onError(error) {
      toast({
        message: error?.message || "Something went wrong while creating the customer.",
        status: "error",
      });
    },
  });

  const createCustomer = useCallback(
    async (name: string) => {
      const optimisticCustomer = makeOptimisticCustomer({ name });

      if (onCreateCustomer) {
        onCreateCustomer(name);
      } else {
        // If the consumer doesn't create on its own, send the optimistic and final customers
        // There could be a race condition if the request takes too long and a user picks something else.
        // Not too worried for now...
        const id = `Customer:${optimisticCustomer.id}`;
        client.writeFragment<CustomerFragment>({
          id,
          fragment: CustomerFragmentDoc,
          fragmentName: "customer",
          data: optimisticCustomer,
        });
        const result = await _createCustomer({ name });
        onSelect(result.data?.createCustomer?.customer ?? null);
      }
    },
    [_createCustomer, client, onCreateCustomer, onSelect]
  );

  const loading = isQueryActive || isPending();
  const customers = customerListResponse?.customers?.items ?? [];

  return (
    <DropdownField<CustomerFragment>
      items={customers}
      onLoadItems={() => {
        if (!loadCustomersCalled) {
          loadCustomers({
            variables: {
              companyId: company!.id,
              filterBy: { name: debouncedFilter },
            },
          });
        }
      }}
      loading={loading}
      filter={filter}
      setFilter={setFilter}
      onSelect={onSelect}
      canEdit={canEdit}
      onCreate={createCustomer}
      label="Pick a customer"
      value={value}
      fullWidth={fullWidth}
      getOptionLabel={(v) => v.name || ""}
      getFreeSoloOption={(name) => ({ name, permaName: name, invoiceStats: { total: 0 } })}
      getEmptyOption={() => ({ name: "No customer", permaName: "", invoiceStats: { total: 0 } })}
      {...props}
    />
  );
};
