import uniqueId from "lodash/uniqueId";
import { useCallback, useEffect, useMemo } from "react";
import Big from "big.js";
import { usePathname } from "next/navigation";
import { useList } from "react-use";

import { CalendarDate, CalendarDateString } from "@puzzle/utils";

import {
  TransactionPageDetailFragment,
  useDeleteSplitMutation,
  useSetSplitsMutation,
  BasicTransactionFragment,
} from "../graphql.generated";

import { CategoryFragment, CustomerFragment } from "graphql/types";
import Analytics from "lib/analytics/analytics";
import { usePendingFixedAssetsQuery } from "components/dashboard/Accounting/FixedAssetsV2/hooks/useFixedAssetsQueries";
import { Route } from "lib/routes";
import { VendorFragment } from "graphql/fragments/vendor.generated";
import { isEqual } from "lodash";
import { isRevenueCategory } from "components/transactions/Cells/VendorCell.utils";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";

export type SplitInput = Omit<TransactionPageDetailFragment, "amount"> & {
  amount?: string;
  accrualDate?: CalendarDateString | null;
};

const UNIQUE_ID = "split";
const REFETCH_ASSETS_DELAY = 7500;

export type EditSplitsType = {
  splitRemainder: Big;
  splits: SplitInput[];
  getSplit: (id: string) => SplitInput | undefined;
  deleteSplits: () => void;
  removeSplit: (id: string) => void;
  addSplit: () => void;
  updateSplitCategory: (id: string, c: CategoryFragment) => SplitInput[];
  updateSplitDescriptor: (id: string, descriptor: string) => SplitInput[];
  updateSplitAccrualDate: (id: string, accrualDate: CalendarDate | null) => SplitInput[];
  updateSplitAmount: (id: string, value?: string) => void;
  updateSplitVendor: (id: string, value?: VendorFragment) => void;
  canSave: boolean;
  persistSplits: (splits: SplitInput[]) => Promise<void>;
  resetSplits: () => void;
  startSplit: () => void;
};

// TODO use react-hook-form?
export function useEditSplits(transaction?: BasicTransactionFragment | null) {
  const [splits, { set, push, removeAt }] = useList<SplitInput>([]);
  const [persistSplitsMutation] = useSetSplitsMutation();
  const [deleteSplitsMutation] = useDeleteSplitMutation();
  const { refetchPendingAssets } = usePendingFixedAssetsQuery(true);
  const pathname = usePathname();

  // Splits mutation resolves before the new fixed assets are created by accounting service
  // need to refetch the assets with a delay/buffer to make sure UI gets newly added assets
  const refetchPendingFixedAssets = () => {
    // only refetch if on fixed asset page
    if (pathname !== Route.fixedAssets) return;

    setTimeout(() => {
      refetchPendingAssets();
    }, REFETCH_ASSETS_DELAY);
  };

  // We normalize splits as positive numbers, since adding an inverse expense is less common.
  // You can still add a negative to make an inverse split.
  // We go back to the original sign when persisting.
  // The number at the top may eventually be normalized; TBD when we do money in/out pages.
  const initialSign = Big(transaction?.amount || 0).lt(0) ? -1 : 1;
  // If we decide we don't like this behavior, you can use this without removing the logic:
  //const initialSign = 1;

  const totalAmount = Big(transaction?.amount || 0).mul(initialSign);

  const areCompleteFragments = useMemo(() => {
    return splits.every((s) => !!s.amount && !!s.descriptor);
  }, [splits]);

  const splitRemainder = useMemo(() => {
    if (splits.length === 0) {
      return Big(0);
    }

    const totalInSplits = splits.reduce((val, s) => {
      return val.add(s.amount || 0);
    }, Big(0));

    return totalAmount.sub(totalInSplits);
  }, [splits, totalAmount]);

  const canSaveSplits = useMemo(() => {
    return splits.length > 1 && splitRemainder && splitRemainder.eq(0) && areCompleteFragments;
  }, [areCompleteFragments, splitRemainder, splits.length]);

  const resetSplits = useCallback(() => {
    const initialSplits =
      transaction?.splits.map((s) => ({
        ...s,
        amount: Big(s.amount || 0)
          .mul(initialSign)
          .toString(),
      })) ?? [];

    if (!isEqual(splits, initialSplits)) {
      set(initialSplits);
    }
  }, [initialSign, set, transaction?.splits, splits]);

  useEffect(() => {
    if (!splits || splits.length === 0) {
      resetSplits();
    }
  }, [resetSplits, splits]);

  const getSplit = (id: string) => splits.find((s) => s.id === id);

  const indexOfSplit = (id: string) => {
    const index = splits.findIndex((s) => s.id === id);
    if (index === -1) {
      throw new Error("Attempted to update a split that doesnt exist");
    }

    return index;
  };

  const deleteSplits = () => {
    if (!transaction) {
      return;
    }

    deleteSplitsMutation({
      variables: { input: { transactionId: transaction.id } },
      optimisticResponse: {
        __typename: "Mutation",
        // TODO nitpick but should be plural
        deleteSplit: {
          __typename: "DeleteSplitResult",
          transaction: {
            ...transaction,
            splits: [],
          },
        },
      },
      onCompleted: () => {
        refetchPendingFixedAssets();
      },
    });

    set([]);
  };

  const removeSplit = (id: string) => {
    const index = indexOfSplit(id);
    removeAt(index);
  };

  const makeSplit = (amount: Big) => {
    if (!transaction) {
      throw new Error("Missing transaction?");
    }

    const initialCategory = transaction.detail.category;
    const isRevenue = isRevenueCategory(initialCategory);
    return {
      id: `${uniqueId(UNIQUE_ID)}`,
      transactionId: transaction.id,
      category: initialCategory,
      descriptor: "",
      categoryIsLocked: false,
      amount: Big(amount).toString(),
      vendor: isRevenue ? null : transaction.detail.vendor,
      customer: isRevenue ? null : transaction.detail.customer,
      classSegments: transaction.detail.classSegments,
    };
  };

  const addSplit = () => {
    push(makeSplit(splitRemainder));
  };

  const startSplit = () => {
    push(makeSplit(totalAmount), makeSplit(Big(0)));
  };

  // FIXME This may not be updating the cache correctly.
  // If you convert a transaction to a split, it takes a few seconds to switch from single transaction to splits.
  const persistSplits = async (splits: SplitInput[]) => {
    if (!(canSaveSplits && areCompleteFragments && transaction)) {
      return;
    }

    const isNewSplitsEnabled = isPosthogFeatureFlagEnabled(FeatureFlag.SplitsV2);

    persistSplitsMutation({
      variables: {
        input: {
          transactionId: transaction.id,
          splits: splits.map((c) => ({
            descriptor: c.descriptor,
            updateOfSplitId: c.id.includes(UNIQUE_ID) ? null : c.id,
            ledgerCoaKey: c.category.permaKey,
            accrualDate: c.accrualDate,
            amount: Big(c.amount || 0)
              .mul(initialSign)
              .toString(),
            vendorPermaName: isNewSplitsEnabled ? c.vendor?.permaName || null : undefined,
            customerId: isNewSplitsEnabled ? c.customer?.id || null : undefined,
          })),
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        setSplits: {
          __typename: "SetSplitResult",
          transaction: {
            ...transaction,
            splits: splits.map((s, i) => ({
              // The split fragment needs more fields than we provide here.
              // We default to an existing split to guarantee the fragment ends up in the cache.
              ...(transaction.splits[i] ?? transaction.detail),
              __typename: "TransactionDetail",
              descriptor: s.descriptor,
              transactionId: transaction.id,
              amount: Big(s.amount || 0)
                .mul(initialSign)
                .toString(),
              id: transaction.splits[i]
                ? transaction.splits[i].id
                : `fake-split-${transaction.id}-${i}`,
              category: s.category,
              categoryIsLocked: false,
            })),
          },
        },
      },
      onCompleted: () => {
        refetchPendingFixedAssets();
      },
    });
    Analytics.transactionSplitsSaved({
      totalSplits: splits.length,
      transactionId: transaction.id,
    });
  };

  const updateSplit = (newSplit: SplitInput, index: number) => {
    if (!transaction) {
      throw new Error("attempted to update a split when the transaction was not defined");
    }

    const newSplits = [...splits];
    newSplits[index] = newSplit;
    set(newSplits);

    return newSplits;
  };

  const updateSplitVendor = (id: string, v?: VendorFragment) => {
    const i = indexOfSplit(id);
    const newSplit = { ...splits[i], vendor: v, customer: null };
    return updateSplit(newSplit, i);
  };

  const updateSplitCustomer = (id: string, c?: CustomerFragment) => {
    const i = indexOfSplit(id);
    const newSplit = { ...splits[i], customer: c, vendor: null };
    return updateSplit(newSplit, i);
  };

  const updateSplitCategory = (id: string, c: CategoryFragment) => {
    const i = indexOfSplit(id);
    const newSplit = { ...splits[i], category: c };
    return updateSplit(newSplit, i);
  };

  const updateSplitDescriptor = (id: string, descriptor: string) => {
    const i = indexOfSplit(id);
    const newSplit = { ...splits[i], descriptor };
    return updateSplit(newSplit, i);
  };

  const updateSplitAccrualDate = (id: string, accrualDate: CalendarDate | null) => {
    const i = indexOfSplit(id);
    const newSplit = { ...splits[i], accrualDate: accrualDate?.toString() };
    Analytics.transactionAccrualDateAdded({ transactionId: id });
    return updateSplit(newSplit, i);
  };

  const updateSplitAmount = (id: string, value?: string) => {
    const i = indexOfSplit(id);
    const newSplits = [...splits];

    // When there's no remainder and there are only two elements,
    // the second input updates the first input so that 1 + 2 = total
    // TBD If this is awkward or needs to be smarter in another way.
    if (splits.length === 2 && i === 1 && splitRemainder.eq(0)) {
      newSplits[0] = {
        ...splits[0],
        amount: totalAmount.sub(value || 0).toString(),
      };
    }

    newSplits[i] = {
      ...splits[i],
      amount: value ? Big(value).toString() : undefined,
    };

    return set(newSplits);
  };

  return {
    splitRemainder,
    splits,
    getSplit,
    deleteSplits,
    removeSplit,
    addSplit,
    updateSplitCategory,
    updateSplitDescriptor,
    updateSplitAccrualDate,
    updateSplitAmount,
    updateSplitVendor,
    updateSplitCustomer,
    canSave: canSaveSplits,
    persistSplits,
    resetSplits,
    startSplit,
  };
}
