import React, { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { useLocalStorage } from "react-use";
import compact from "lodash/compact";
import useResizeObserver from "use-resize-observer";
import { useDebouncedCallback } from "use-debounce";
import dynamic from "next/dynamic";

import {
  DataGrid,
  MRT_RowData,
  MRT_ExpandedState,
  MRT_TableInstance,
  tableBodyCellRecipe,
  tableHeaderCellRecipe,
  TableHeadStyle,
} from "data-grid";
import { S, colors } from "@puzzle/theme";
import { Move, IconProps, Checkbox, CheckboxChecked } from "@puzzle/icons";
import { zIndex } from "@puzzle/utils";

import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import { useAppRouter } from "lib/useAppRouter";
import Analytics from "lib/analytics/analytics";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { BottomToolbar } from "./BottomToolbar";
import { TopToolbar } from "./TopToolbar";
import {
  TransactionRowData,
  transactionToRowData, // new transform function for testing purposes now, future implementation
  defaultColumnVisibility,
  defaultColumnOrder,
} from "./shared";
import { useTransactionsPage } from "./TransactionsProvider";
import {
  accountColumn,
  vendorCustomerColumn,
  descriptionColumn,
  amountColumn,
  statusColumn,
  assigneeColumn,
  classificationsColumn,
  departmentColumn,
  locationColumn,
  classesColumn,
  dateColumn,
  categoryColumn,
  SortByColumnOptions,
} from "./columns";
import { BasicTransactionFragment } from "./graphql.generated";
import { useBulkRecategorizeStore } from "./hooks/useBulkRecategorizeStore";
import { useTransactionsTargetType } from "./hooks/useTransactionsTargetType";
import { useTransactionsHotkeys } from "./hooks/useTransactionsHotkeys";
import { ActiveFilters } from "./ActiveFilters";
import { DATA_TEST_IDS } from "./constants";

const DynamicBulkRecategorizeModalPaginated = dynamic(() =>
  import("./BulkRecategorizeModalPaginated").then((mod) => mod.BulkRecategorizeModalPaginated)
);
const DynamicTransactionsFloatingActionsPaginated = dynamic(() =>
  import("./TransactionsFloatingActionsPaginated").then(
    (mod) => mod.TransactionsFloatingActionsPaginated
  )
);

export type RowSelectionType = Record<string, boolean>;

export const MOUSE_HOVER_CLASSNAME = "mouseHoverable";
export const KBD_HOVER_CLASSNAME = "kbd-hover";
export const DEFAULT_PAGE_SIZE = 25;
const CHECKBOX_SIZE = 19;
const CHECKBOX_CHECKED_SIZE = 26;
const CHECKBOX_ALL_CHECKED_SIZE = 36;
const CHECK_BOX_ICON_STYLE = {
  marginTop: 3,
  marginLeft: 3,
};

const MIN_TABLE_HEIGHT = 300;

export const TransactionsPaginated = ({ id }: { id?: string }) => {
  //
  /* ----------------- REFS ----------------- */
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<MRT_TableInstance<MRT_RowData> | null>(null);
  const rowRefs = useRef<Map<string, HTMLTableRowElement[]>>(new Map());

  /* ----------------- STATE ----------------- */
  const [selectAllRows, setSelectAllRows] = useState(false);
  const [rowSelection, setRowSelection] = useState<RowSelectionType>({}); // Manage selection state ourselves
  const [selectedRowData, setSelectedRowData] = useState<BasicTransactionFragment[]>([]);
  const [sorting, setSorting] = useState<any[]>([]);
  const [tableHeight, setTableHeight] = useState("100vh");

  const [focusedTransactionId, setFocusedTransactionId] = useState<string | null>(null);
  const [didUserKbdNavigate, setDidUserKbdNavigate] = useState(false);
  const { isOpen: isBulkRecategorizeOpen, setIsOpen: setIsBulkRecategorizeOpen } =
    useBulkRecategorizeStore();

  /* ----------------- LOCAL STORAGE ----------------- */
  const [columnVisibility = defaultColumnVisibility, setColumnVisibility] = useLocalStorage(
    "pz:transactions-mrt-col-vis",
    defaultColumnVisibility
  );
  const [columnOrder = defaultColumnOrder, setColumnOrder] = useLocalStorage(
    "pz:transactions-mrt-col-order-2",
    defaultColumnOrder
  );

  const [storagePageSize, setStoragePageSize] = useLocalStorage(
    "pz:transactions-mrt-page-size",
    DEFAULT_PAGE_SIZE
  );

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: storagePageSize ?? DEFAULT_PAGE_SIZE,
  });

  /* ----------------- CUSTOM HOOKS ----------------- */
  const { isWithinLockedPeriod } = useActiveCompany<true>();

  const dateFormatter = useCompanyDateFormatter({
    dateStyle: "medium",
  });

  const {
    setActiveTransaction,
    activeTransactionId,
    setActiveTransactionId,
    loading,
    hasMore,
    fetchNextPage,
    totals,
    filter,
    categories,
    setSortOptions,
    companyId,
    queryVariables,
    transactionsByPage,
    setFilter,
  } = useTransactionsPage();

  const { goToRules } = useAppRouter();

  // If the user resizes the window, we need to recalculate the table height
  useResizeObserver({
    ref: tableContainerRef,
    onResize: ({ width }) => {
      if (width) {
        const calculatedTableHeight = getCalculatedTableHeight(tableContainerRef);
        setTableHeight(calculatedTableHeight);
      }
    },
  });

  const {
    isAnyRowHovered,
    setIsAnyRowHovered,
    setIsAnyRowSelected,
    setIsAnyRowActive,
    setActiveRowInfo,
    setFirstSelectedRowInfo,
    setNumOfSelectedRows,
    isAnyRowSelected,
  } = useTransactionsTargetType();

  const debouncedSetFocusedTransactionId = useDebouncedCallback(
    (id: string | null) => setFocusedTransactionId(id),
    30
  );

  const debouncedSetIsAnyRowHovered = useDebouncedCallback(
    (val: boolean) => setIsAnyRowHovered(val),
    60
  );
  /* ----------------- FEATURE FLAGS ----------------- */
  const enableClassesAndDeptsColumns = isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsCols);

  /* ----------------- MEMOIZED DATA ----------------- */
  const data: TransactionRowData[] = useMemo(() => {
    if (!transactionsByPage) {
      return [];
    } else {
      return transactionToRowData(transactionsByPage[pagination.pageIndex]);
    }
  }, [transactionsByPage, pagination.pageIndex]);

  const columns = useMemo(() => {
    return compact([
      dateColumn(filter, dateFormatter, { sortBy: SortByColumnOptions.AscFirst }),
      accountColumn,
      vendorCustomerColumn,
      descriptionColumn,

      // TODO UpdateCategoryMenu is the biggest performance hit.
      // Either start virtualizing or attempt to extract it instead of completely embedding it.
      categoryColumn(categories),

      !enableClassesAndDeptsColumns && classificationsColumn,
      enableClassesAndDeptsColumns && departmentColumn,
      enableClassesAndDeptsColumns && locationColumn,
      enableClassesAndDeptsColumns && classesColumn,

      statusColumn,
      assigneeColumn,
      amountColumn,
    ]);
  }, [dateFormatter, categories, enableClassesAndDeptsColumns, filter]);

  /* ----------------- EFFECTS ----------------- */

  /*

  An explanation of the different states a row can be in:

  FOCUSED: The row is hovered over or tabbed to via keyboard or screen reader.
  Only 1 row may be FOCUSED at a time.

  ACTIVE: This row has been activated and the side drawer is open.
  Only 1 row may be ACTIVE at a time.

  SELECTED: This row has been selected with its checkbox or hotkey.
  Multiple rows (including all in the whole table) may be SELECTED at a time.

  These states are independent and may be combined.
  The same row may be FOCUSED, ACTIVE, and SELECTED at the same time.
  Or some rows might be SELECTED while another is ACTIVE and yet another is FOCUSED.

*/

  useEffect(() => {
    // Attach refs and transaction ids to rows
    const rows = document.querySelectorAll(
      "tr[data-transaction-id]"
    ) as NodeListOf<HTMLTableRowElement>;
    const newRowRefs = new Map<string, HTMLTableRowElement[]>();
    rows.forEach((row) => {
      const transactionId = row.getAttribute("data-transaction-id");
      if (transactionId) {
        if (!newRowRefs.has(transactionId)) {
          newRowRefs.set(transactionId, []);
        }
        newRowRefs.get(transactionId)?.push(row);
      }
    });
    rowRefs.current = newRowRefs;
  }, [data]);

  useEffect(() => {
    // if any rows are selected, set isAnyRowSelected to true
    if (Object.keys(rowSelection).length > 0) {
      if (!isAnyRowSelected) {
        setIsAnyRowSelected(true);
      }
    } else {
      if (isAnyRowSelected) {
        setIsAnyRowSelected(false);
      }
    }

    // Set the selected row data based on the row selection
    const selectedTransactions = Object.keys(rowSelection).map((key) => {
      return transactionsByPage?.flat().find((transaction) => transaction.id === key);
    });
    /*
      We are not using table.getSelectedRowModel().rows. See the following from the docs:

      Note: table.getSelectedRowModel().rows only really works with client-side pagination.
      Use rowSelection state if you are using server-side pagination.
      https://www.material-react-table.com/docs/guides/row-selection#read-row-selection-state-from-table-instance
    */
    const validSelectedTransactions = selectedTransactions.filter(
      (transaction): transaction is BasicTransactionFragment => transaction !== undefined
    );
    setSelectedRowData(validSelectedTransactions);
    const selectedRowsLength = validSelectedTransactions.length;
    // We want the "select all" option to show the total number of transactions in the whole table
    // We set that in the useEffect dependent on selectAllRows
    // If the user is not selecting all rows, we want to set the number of selected rows
    if (!selectAllRows) {
      setNumOfSelectedRows(selectedRowsLength);
    }
    if (selectedRowsLength === 1) {
      const selectedTransaction = validSelectedTransactions[0];
      const selectedRowData = {
        vendor: selectedTransaction.detail.vendor?.name
          ? selectedTransaction.detail.vendor.name
          : "",
        amount: selectedTransaction.amount,
      };
      setFirstSelectedRowInfo(selectedRowData);
    }
  }, [
    rowSelection,
    isAnyRowSelected,
    selectAllRows,
    setFirstSelectedRowInfo,
    setIsAnyRowSelected,
    setNumOfSelectedRows,
    transactionsByPage,
  ]);

  // Sync our select all state with the table's select all state
  const getIsAllRowsSelected = tableRef?.current?.getIsAllRowsSelected();
  useEffect(() => {
    setSelectAllRows(tableRef?.current?.getIsAllRowsSelected() || false);
  }, [getIsAllRowsSelected]);

  useEffect(() => {
    if (selectAllRows) {
      tableRef?.current?.toggleAllRowsSelected(true);
      setIsAnyRowSelected(true);
      setNumOfSelectedRows(totals.count);
    } else {
      setIsAnyRowSelected(false);
      setNumOfSelectedRows(0);
    }
  }, [selectAllRows, setIsAnyRowSelected, setNumOfSelectedRows, totals.count]);

  useEffect(() => {
    setSortOptions(sorting);
  }, [sorting, setSortOptions]);

  useEffect(() => {
    // Reset to first page whenever a new filter or sort is applied via queryVariables
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }));
  }, [queryVariables]);

  // Pagination
  useEffect(() => {
    const fetchData = async () => {
      if (pagination.pageIndex * pagination.pageSize >= data.length) {
        fetchNextPage(); // at this point, transactionsByPage is updated
      }
    };
    if (!transactionsByPage || pagination.pageIndex === transactionsByPage.length) {
      fetchData(); // at this point, transactionsByPage is updated
    }
  }, [
    pagination.pageIndex, //re-fetch when page index changes
    pagination.pageSize, //re-fetch when page size changes
    sorting, //re-fetch when sorting changes
    data.length,
    fetchNextPage,
    transactionsByPage,
  ]);

  useEffect(() => {
    if (isAnyRowHovered === false && focusedTransactionId !== null) {
      setFocusedTransactionId(null);
    }
  }, [isAnyRowHovered, focusedTransactionId]);

  useEffect(() => {
    // if any rows are active, set isAnyRowActive to true
    if (activeTransactionId) {
      // find this active transaction in the current page
      const currentPageTransactions = transactionsByPage?.[pagination.pageIndex] || [];
      const transaction = currentPageTransactions.find((t) => t.id === activeTransactionId);
      if (transaction) {
        const activeRowData = {
          vendor: transaction.detail.vendor?.name ? transaction.detail.vendor.name : "",
          amount: transaction.amount,
        };
        setActiveRowInfo(activeRowData);
      }
      setIsAnyRowActive(true);
    } else {
      setIsAnyRowActive(false);
    }
  }, [
    activeTransactionId,
    setIsAnyRowActive,
    pagination.pageIndex,
    setActiveRowInfo,
    transactionsByPage,
  ]);

  /* ----------------- FUNCTIONS ----------------- */
  const toggleSelectAllRows = () => setSelectAllRows((prev) => !prev);
  const clearSelection = () => setRowSelection({});

  const onRowClick = useCallback(
    (row: MRT_RowData) => {
      if (row.original.transactionId) {
        setActiveTransaction(row.original.transactionId);
        Analytics.transactionsTableRowClicked({
          transactionId: row.original.transactionId,
        });
      }
    },
    [setActiveTransaction]
  );

  const getCalculatedTableHeight = useCallback(
    (tableContainerRef: { current: HTMLDivElement | null }): string => {
      const topHeight = tableContainerRef.current?.getBoundingClientRect().top || 0;
      const viewportHeight = window.innerHeight - 64; // 64 is the height of InfiniteScrollFooter + margins - NOTE: this code will be deleted and replaced with pagination ASAP
      const calculatedTableHeight = `calc(${Math.max(
        viewportHeight - topHeight,
        MIN_TABLE_HEIGHT
      )}px)`;
      return calculatedTableHeight;
    },
    []
  );

  useEffect(() => {
    const calculatedTableHeight = getCalculatedTableHeight(tableContainerRef);
    setTableHeight(calculatedTableHeight);
  }, [setTableHeight, getCalculatedTableHeight, tableContainerRef]);

  const toggleRowSelection = (transactions: BasicTransactionFragment[]) => {
    /*
      We are managing row selection ourselves because of our "select all" and pagination needs.
      So we need a new function to handle toggling row selection for transactions and splits.
    */

    // Create an empty array to store any splits that need to be toggled
    let splitsToToggle: RowSelectionType[] = [];
    // Map over all transactions and reshape them into an array of RowSelectionType objects
    const transactionsToToggle = transactions.map((transaction) => {
      // If this transaction has splits
      if (transaction.splits.length > 0) {
        // Reassign splitsToToggle with the following:
        splitsToToggle = [
          // 1. Previous splits
          ...splitsToToggle,
          // 2. All the splits from this transaction
          ...transaction.splits.map((split) => {
            return { [split.id]: true };
          }),
        ];
      }
      // Return an object with the transaction id set to true
      return { [transaction.id]: true };
    });

    const rowsToToggle = [...transactionsToToggle, ...splitsToToggle];
    /*
      This produces an array of objects that look like this:
        [
          { 4cffd85e-bf95-4f40-8829-00138cc3d6b1: true },
        ]
      Row selection in MRT is denoted by adding or removing objects from this array.
      The value of "true" is actually irrelevant, as we are just using the keys.
    */
    setRowSelection((prev) => {
      const newRowSelection = { ...prev };
      rowsToToggle.forEach((row) => {
        const rowKey = Object.keys(row)[0];
        if (rowKey in newRowSelection) {
          delete newRowSelection[rowKey];
        } else {
          newRowSelection[rowKey] = true;
        }
      });
      return newRowSelection;
    });
  };

  const isThisTransactionOrOneOfItsSplitsFocused = (rowTransactionId: string) => {
    const allTransactionsOnThisPage = transactionsByPage?.[pagination.pageIndex] || [];
    const currentTransactionIndex = allTransactionsOnThisPage?.findIndex(
      (transaction) => transaction.id === focusedTransactionId
    );
    const currentTransaction = allTransactionsOnThisPage[currentTransactionIndex];
    const isTransactionFocused = currentTransaction?.id === rowTransactionId;
    const isSplitFocused = currentTransaction?.splits.some(
      (split) => split.id === rowTransactionId
    );
    return isTransactionFocused || isSplitFocused;
  };

  /* ----------------- HOTKEYS ----------------- */

  useTransactionsHotkeys({
    focusedTransactionId,
    transactionsByPage,
    paginationPageIndex: pagination.pageIndex,
    rowSelection,
    activeTransactionId,
    isAnyRowHovered,
    setIsBulkRecategorizeOpen,
    toggleRowSelection,
    setActiveTransactionId,
    setActiveTransaction,
    clearSelection,
    id,
    setFocusedTransactionId,
    tableContainerRef,
    rowRefs: rowRefs.current,
    didUserKbdNavigate,
    setDidUserKbdNavigate,
    goToRules,
  });

  /* ----------------- TABLE OPTIONS ----------------- */
  const tableOptions = {
    data,
    columns,
    enableSorting: true,
    enableColumnOrdering: true,
    // This is a performance optimization but we lose these features: row density toggle, column resizing
    // https://www.material-react-table.com/docs/guides/memoization#memo-mode
    // https://v1.material-react-table.com/docs/guides/memoize-components#cell-memoization-breaks-the-following-features:
    memoMode: "cells" as const,
    /*
      We have to fiddle with virtualization to get the performance where we want it.
      We have to consider the laginess of mouse hover & hotkey effects and how rows appear when scrolling.
    */
    enableRowVirtualization: true,
    rowVirtualizerOptions: { overscan: 100 },
    manualSorting: true,
    onSortingChange: setSorting,
    enablePagination: true,
    enableBottomToolbar: true,
    enableTopToolbar: true,
    // positionPagination: "both",
    paginateExpandedRows: true, // this must be TRUE to show splits properly
    muiPaginationProps: {
      showRowsPerPage: false, // must be false to show custom rows per page functionality
      showLastButton: false, // Not implementing until BE has a way to jump the last page and reverse fetch
      showFirstButton: true,
      disabled: loading,
    },
    manualPagination: true,
    rowCount: totals.count,
    onPaginationChange: setPagination, //hoist pagination state to local state when it changes internally

    // 🎨 CUSTOM ICONS: https://www.material-react-table.com/docs/guides/customize-icons

    icons: {
      DragHandleIcon: (props: IconProps) => (
        // To get the icons to line up and look cohesive, we do a little pixel-pushing
        <Move {...props} width={15} height={15} style={{ marginTop: 1, marginLeft: 4 }} />
      ),
    },
    muiColumnDragHandleProps() {
      return {
        "aria-label": "Drag to reorder",
        // We are removing the title attribute to remove the tooltip because there is a bug in the tooltip:
        // when a column is dragged the tooltip is not removed and it stays on the screen.
        // Between the icon and the cursor change,
        // users can understand that they can drag the column without the tooltip.
        title: "",
      };
    },

    // 🌴 STATE MANAGEMENT: https://www.material-react-table.com/docs/guides/state-management

    initialState: { expanded: true as MRT_ExpandedState, columnOrder, pagination, isLoading: true },
    state: {
      pagination,
      isLoading: loading,
      showLoadingOverlay: loading,
      showSkeletons: loading,
      rowSelection,
      columnOrder,
      sorting,
      columnVisibility: {
        ...columnVisibility,
        "mrt-row-expand": false, // hide the subrow expand column
      },
    },

    // 💪 HANDLERS

    // Handle column order change: https://www.material-react-table.com/docs/guides/column-ordering-dnd#relevant-table-options
    // NOTE: we are using useLocalStorage whose type definition is different
    // from the onColumnOrderChange type in the docs, hence the type cast below
    onColumnOrderChange: (
      updaterOrValue: string[] | ((arg0: string[]) => string[] | undefined) | undefined
    ) => {
      setColumnOrder((prevState = []) =>
        typeof updaterOrValue === "function" ? updaterOrValue(prevState) : updaterOrValue
      );
    },

    // 🖥️ ROWS
    enableMultiRowSelection: true,
    // In getRowId below, replace each row id with a unique identifier
    // so that selecting rows works across pagination.
    // Without this this uuid, it uses the row index (which is not unique across pages)
    // See getRowId in https://www.material-react-table.com/docs/guides/row-selection#relevant-table-options
    getRowId: (originalRow: MRT_RowData) => originalRow.id,
    // Passing a function to enableRowSelection seems to always return true.
    // It's old code, so I'm not rewriting the logic now.
    // And maybe it works in our old, non-paginated table. TODO: investigate.
    enableRowSelection: (row: MRT_RowData) =>
      !row?.original?.date || !isWithinLockedPeriod(row?.original?.date),
    onRowSelectionChange: setRowSelection,
    // enableRowNumbers: true, // for debugging purposes
    muiTableBodyRowProps: ({ row }: MRT_RowData) => {
      const rowTransactionId = row.parentId || row.id;

      return {
        role: "row",
        "data-transaction-id": rowTransactionId,
        onClick: () => {
          onRowClick(row);
        },
        onMouseMove: (e: React.MouseEvent<HTMLTableRowElement> & { target: HTMLElement }) => {
          // Only handle mouse movement if it's not from scrolling
          if (e.movementX !== 0 || e.movementY !== 0) {
            // Remove all "kbd-hover" classes from all rows
            if (didUserKbdNavigate) {
              setDidUserKbdNavigate(false);
              rowRefs.current.forEach((rowRef) => {
                rowRef.forEach((rowElement) => {
                  rowElement.classList.remove(KBD_HOVER_CLASSNAME);
                });
              });
            }

            // Handle splits and their parent transactions as "related rows"
            const rowElementUnderMouse = e.target.closest('[role="row"]');
            if (
              rowElementUnderMouse &&
              !rowElementUnderMouse.classList.contains(MOUSE_HOVER_CLASSNAME)
            ) {
              const parentTransactionId = rowElementUnderMouse.getAttribute("data-transaction-id");

              const relatedRows = parentTransactionId
                ? rowRefs.current.get(parentTransactionId)
                : [];

              if (relatedRows && relatedRows.length > 0) {
                relatedRows.forEach((rowElement) => {
                  if (!rowElement.classList.contains(MOUSE_HOVER_CLASSNAME)) {
                    rowElement.classList.add(MOUSE_HOVER_CLASSNAME);
                  }
                });
              }
            }

            // Set the focused transaction id after a short delay (so we don't see a lag in the hover effect)
            if (rowTransactionId !== focusedTransactionId) {
              debouncedSetFocusedTransactionId(rowTransactionId);
            }
          }
        },
        onMouseEnter: () => {
          if (!isAnyRowHovered) {
            debouncedSetIsAnyRowHovered(true);
          }
        },
        onMouseLeave: () => {
          if (isAnyRowHovered) {
            debouncedSetIsAnyRowHovered(false);
          }
        },
        sx: {
          cursor: "pointer",
          // Remove default MRT backgroundColor
          "td:after": {
            backgroundColor: "transparent",
          },
          // Clear default MRT hover styles
          "&:hover": {
            backgroundColor: "transparent",
            "td:after": {
              backgroundColor: "transparent",
            },
          },
          [`&.${KBD_HOVER_CLASSNAME}`]: {
            backgroundColor: `${colors.mauve700} !important`,
            "td:after": {
              backgroundColor: "transparent",
            },
          },
          // Set the background color based on row state:
          backgroundColor:
            // If this row is the focused transaction & does not have the class of mouseHoverable, lighten
            isThisTransactionOrOneOfItsSplitsFocused(rowTransactionId) &&
            !document.querySelector(`.${MOUSE_HOVER_CLASSNAME}`)
              ? `${colors.mauve700} !important`
              : // If this row is the active transaction, lighten
                activeTransactionId === row.original.transactionId
                ? `${colors.mauve700} !important`
                : // If row is selected but not active, darken
                  row.getIsSelected()
                  ? `${colors.mauve900} !important`
                  : // Otherwise use inherited color
                    "inherit",

          // // Selector to handle hover states for rows related to the same transaction with a mouseHoverable class
          // This is needed to highlight split rows with parent transaction rows when either is hovered
          [`&[data-transaction-id="${rowTransactionId}"].${MOUSE_HOVER_CLASSNAME}:has(~ tr[data-transaction-id="${rowTransactionId}"].${MOUSE_HOVER_CLASSNAME}:hover),
          &[data-transaction-id="${rowTransactionId}"].${MOUSE_HOVER_CLASSNAME}:hover,
          &[data-transaction-id="${rowTransactionId}"].${MOUSE_HOVER_CLASSNAME}:hover ~ tr[data-transaction-id="${rowTransactionId}"].${MOUSE_HOVER_CLASSNAME},
          &.${MOUSE_HOVER_CLASSNAME}:has(~ tr[data-transaction-id="${rowTransactionId}"].${MOUSE_HOVER_CLASSNAME}:hover)`]:
            {
              // Remove default MRT backgroundColor on hover
              "td:after": {
                backgroundColor: "transparent",
              },
              // Apply hover background color to all related transaction rows
              backgroundColor: `${colors.mauve700} !important`,
            },
        },
      };
    },
    muiSelectCheckboxProps: ({ row }: MRT_RowData) => {
      const isSplitRow = row?.original?.splitIndex !== undefined;
      return {
        sx: {
          display: isSplitRow ? "none" : "block",
        },
        icon: (
          <Checkbox width={CHECKBOX_SIZE} height={CHECKBOX_SIZE} style={CHECK_BOX_ICON_STYLE} />
        ),
        checkedIcon: (
          <CheckboxChecked
            width={CHECKBOX_CHECKED_SIZE}
            height={CHECKBOX_CHECKED_SIZE}
            style={CHECK_BOX_ICON_STYLE}
          />
        ),
      };
    },
    muiSelectAllCheckboxProps: () => {
      return {
        icon: (
          <Checkbox width={CHECKBOX_SIZE} height={CHECKBOX_SIZE} style={CHECK_BOX_ICON_STYLE} />
        ),
        checkedIcon: (
          <CheckboxChecked
            width={CHECKBOX_ALL_CHECKED_SIZE}
            height={CHECKBOX_ALL_CHECKED_SIZE}
            style={CHECK_BOX_ICON_STYLE}
          />
        ),
        indeterminateIcon: (
          <Checkbox width={CHECKBOX_SIZE} height={CHECKBOX_SIZE} style={CHECK_BOX_ICON_STYLE} />
        ),
      };
    },
    renderTopToolbar: () => {
      return (
        <>
          <TopToolbar
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            pagination={pagination}
            loading={loading}
            totals={totals}
            setPagination={setPagination}
            setStoragePageSize={setStoragePageSize}
            setFilter={setFilter}
          />
          <ActiveFilters />
        </>
      );
    },
    renderBottomToolbar: () => {
      return (
        <BottomToolbar
          pagination={pagination}
          loading={loading}
          totals={totals}
          setPagination={setPagination}
          setStoragePageSize={setStoragePageSize}
          setFilter={setFilter}
        />
      );
    },

    // #️⃣ SUBROWS

    enableExpanding: true, // required for any subrow functionality
    enableExpandAll: false,
    getSubRows: (originalRow: MRT_RowData) => originalRow.subRows,

    // 💅 STYLE

    muiTableHeadCellProps: {
      className: tableHeaderCellRecipe({ border: "borderless" }),
    },
    muiTableBodyCellProps: {
      className: tableBodyCellRecipe({ border: "borderless" }),
      sx: {
        height: S.$6,
        paddingTop: "0",
        paddingBottom: "0",
      },
    },
    muiTableHeadProps: {
      className: TableHeadStyle,
    },
    muiTableHeadRowProps: {
      sx: {
        zIndex: zIndex("tableStickyHeader"),
        position: "sticky",
      },
    },
    muiTableProps: {
      "data-testid": DATA_TEST_IDS.TRANSACTIONS_TABLE, // Add this to table element for testing
      sx: {
        tbody: {
          zIndex: zIndex("background"),
        },
        thead: {
          zIndex: zIndex("tableStickyHeader"),
        },
      },
    },

    muiTableContainerProps: {
      sx: { height: tableHeight },
      ref: tableContainerRef,
    },
  };

  const hasSelectedRows = (selectAllRows ? totals.count : selectedRowData.length) > 0;

  return (
    <>
      <DataGrid options={tableOptions} height="100%" tableRef={tableRef as any} />
      {isBulkRecategorizeOpen && (
        <DynamicBulkRecategorizeModalPaginated
          transactions={selectedRowData}
          categories={categories}
          open={isBulkRecategorizeOpen}
          onOpenChange={setIsBulkRecategorizeOpen}
          companyId={companyId}
          toggleRowSelection={toggleRowSelection}
        />
      )}
      {hasSelectedRows && (
        <DynamicTransactionsFloatingActionsPaginated
          data={data}
          selectedRows={selectedRowData}
          matchAll={selectAllRows}
          toggleMatch={toggleSelectAllRows}
          resetSelection={clearSelection}
          companyId={companyId}
          hasMore={hasMore}
          totalCount={totals.count}
          queryVariables={queryVariables}
          toggleRowSelection={toggleRowSelection}
          rowSelection={rowSelection}
        />
      )}
    </>
  );
};
