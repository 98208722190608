export const DATA_TEST_IDS = {
  TRANSACTIONS_SCREEN: "transactions-screen",
  TRANSACTIONS_SCREEN_HEADER: "transactions-screen-header",
  TRANSACTIONS_SCREEN_BODY: "transactions-screen-body",
  TRANSACTIONS_SCREEN_PANEL: "transactions-screen-panel",
  TRANSACTIONS_TAB_ALL: "transactions-tab-all",
  TRANSACTIONS_TABLE: "transactions-table",
  IMPORT_TRANSACTIONS_BUTTON: "import-transactions-button",
  TRANSACTIONS_TABLE_TOP_TOOLBAR: "transactions-table-top-toolbar",
  TRANSACTIONS_TABLE_BOTTOM_TOOLBAR: "transactions-table-bottom-toolbar",
};
