import React from "react";
import Icon, { IconProps } from "./Icon";
import { colors } from "@puzzle/theme";

export function Bank({
  viewBox = "0 0 18 17",
  width = 18,
  height = 17,
  fill = colors.green800,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} viewBox={viewBox} {...iconProps}>
      <path
        d="M17.4375 15C17.5781 15 17.7188 15.0703 17.8242 15.1758C17.9297 15.2812 18 15.4219 18 15.5625V15.8438C18 15.9141 17.9648 15.9844 17.8945 16.0547C17.8242 16.125 17.7891 16.125 17.7188 16.125H0.28125C0.210938 16.125 0.140625 16.125 0.0703125 16.0547C0 15.9844 0 15.9141 0 15.8438V15.5625C0 15.4219 0.0351562 15.2812 0.140625 15.1758C0.246094 15.0703 0.386719 15 0.5625 15H1.125V13.5938C1.125 13.3828 1.19531 13.1719 1.37109 12.9961C1.51172 12.8555 1.72266 12.75 1.96875 12.75H3.375V6.5625H4.5V12.75H6.75V6.5625H7.875V12.75H10.125V6.5625H11.25V12.75H13.5V6.5625H14.625V12.75H16.0312C16.2422 12.75 16.4531 12.8555 16.6289 12.9961C16.7695 13.1719 16.875 13.3828 16.875 13.5938V15H17.4375ZM2.25 13.875V15H15.75V13.875H2.25ZM17.7188 4.03125C17.8945 4.10156 18 4.24219 18 4.41797V4.73438C18 4.875 17.9297 4.98047 17.8594 5.05078C17.7891 5.12109 17.6836 5.15625 17.5781 5.15625H16.875V5.57812C16.875 5.71875 16.8047 5.82422 16.7344 5.89453C16.6641 5.96484 16.5586 6 16.4531 6H1.54688C1.40625 6 1.30078 5.96484 1.23047 5.89453C1.16016 5.82422 1.125 5.71875 1.125 5.57812V5.15625H0.421875C0.28125 5.15625 0.175781 5.12109 0.105469 5.05078C0.0351562 4.98047 0 4.875 0 4.73438V4.41797C0 4.24219 0.0703125 4.10156 0.28125 4.03125L8.47266 0.445312C8.82422 0.339844 9.17578 0.339844 9.52734 0.445312L17.7188 4.03125ZM15.75 4.875V4.38281L9.14062 1.53516C9.03516 1.5 8.92969 1.5 8.85938 1.53516L2.25 4.38281V4.875H15.75Z"
        fill={fill}
      />
    </Icon>
  );
}

// I think this is actually BankSolid?
export function BankOutline({
  viewBox = "0 0 18 18",
  width = 18,
  height = 18,
  fill = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} viewBox={viewBox} {...iconProps}>
      <path
        fill={fill}
        d="M3.75 8.25h1.5v5.25h-1.5V8.25zM8.25 8.25h1.5v5.25h-1.5V8.25zM1.875 15h14.25v1.5H1.875V15zM12.75 8.25h1.5v5.25h-1.5V8.25zM1.875 5.25L9 1.5l7.125 3.75v1.5H1.875v-1.5z"
      ></path>
    </Icon>
  );
}
