import { TransactionRowData } from "components/dashboard/Transactions/shared";
import { BasicTransactionFragment } from "components/dashboard/Transactions/graphql.generated";
import { CategoryFragment } from "graphql/types";

export const isRevenueCategory = (category?: CategoryFragment): boolean =>
  category?.__typename === "LedgerCategory" && category?.defaultCashlike === "REVENUE";

export const getParentLabel = (transaction?: BasicTransactionFragment): string => {
  if (!transaction) return "No counterparties";
  const splits = transaction.splits || [];

  // Handle parent transaction with splits
  if (splits.length > 0) {
    const vendors = splits
      .filter((s) => !isRevenueCategory(s.category))
      .map((s) => s.vendor)
      .filter((v) => !!v);
    const customers = splits
      .filter((s) => isRevenueCategory(s.category))
      .map((s) => s.customer)
      .filter((c) => !!c);

    if (vendors.length === 0 && customers.length === 0) {
      return "No counterparties";
    }

    // Check for single unique vendor with no customers
    if (vendors.length > 0 && customers.length === 0) {
      const uniqueVendorIds = new Set(vendors.map((v) => v?.id));
      if (uniqueVendorIds.size === 1) {
        return vendors[0]?.name || "No vendor";
      }
      return "Multiple vendors";
    }

    // Check for single unique customer with no vendors
    if (customers.length > 0 && vendors.length === 0) {
      const uniqueCustomerIds = new Set(customers.map((c) => c?.id));
      if (uniqueCustomerIds.size === 1) {
        return customers[0]?.name || "No customer";
      }
      return "Multiple customers";
    }

    return "Multiple counterparties";
  }

  // Handle parent transaction without splits
  const transactionCategory = transaction.detail.category;
  const isRevenue = isRevenueCategory(transactionCategory);

  return isRevenue
    ? transaction.detail.customer?.name || "No customer"
    : transaction.detail.vendor?.name || "No vendor";
};

export const getSplitLabel = (rowData: TransactionRowData): string => {
  const splitCategory = rowData.category;
  const isRevenue = isRevenueCategory(splitCategory);
  return isRevenue ? rowData.customer?.name || "No customer" : rowData.vendor?.name || "No vendor";
};

export const getCounterpartyLabel = (rowData: TransactionRowData | undefined): string => {
  if (!rowData) return "No counterparties";

  const isParent = rowData.id === rowData.transaction.id;
  return isParent ? getParentLabel(rowData?.transaction) : getSplitLabel(rowData);
};
