import { colors } from "@puzzle/theme";
import Icon, { IconProps } from "./Icon";

export default function CheckboxChecked({
  width = 24,
  height = 24,
  viewBox = "0 0 24 24",
  fill = "none",
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} fill={fill} viewBox={viewBox} {...iconProps}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 3C0 1.34315 1.34315 0 3 0H11C12.6569 0 14 1.34315 14 3V11C14 12.6569 12.6569 14 11 14H3C1.34315 14 0 12.6569 0 11V3Z"
          fill={colors.green600}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4802 3.42385C11.7984 3.68903 11.8414 4.16195 11.5762 4.48016L6.57618 10.4802C6.43369 10.6512 6.2226 10.75 6.00002 10.75C5.77743 10.75 5.56635 10.6512 5.42385 10.4802L2.92385 7.48016C2.65868 7.16195 2.70167 6.68903 3.01988 6.42385C3.33809 6.15868 3.81101 6.20167 4.07618 6.51988L6.00002 8.82848L10.4239 3.51988C10.689 3.20167 11.1619 3.15868 11.4802 3.42385Z"
          fill={colors.mauve800}
        />
      </svg>
    </Icon>
  );
}
