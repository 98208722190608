import React, { useRef, useEffect, useState } from "react";

import { Button } from "ve";
import { colors, S } from "@puzzle/theme";
import { Toolbar } from "@puzzle/ui";
import { ChevronRight, ChevronLeft, Download2, Add } from "@puzzle/icons";
import Analytics from "lib/analytics/analytics";

import { TransactionReportExportModal } from "components/reports/modals/TransactionReportExportModal";
import { ColumnVisibilityMenu } from "./ColumnVisibilityMenu";
import { ImportTransactionsModal } from "./ImportTransactionsModal/ImportTransactionsModal";
import { ColumnVisibility } from "./shared";
import { Totals } from "./Totals";
import { FilterToolbar } from "./FilterToolbar";
import {
  container,
  topRow,
  bottomRow,
  bottomRowText,
  bottomRowControls,
  bottomRowControlsPagination,
  containerWrapper,
  bottomRowControlsTable,
} from "./topToolbar.css";
import { DATA_TEST_IDS } from "./constants";

type PaginationToolbarProps = {
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  loading: boolean;
  totals: {
    count: number;
  };
  setPagination: (pagination: { pageIndex: number; pageSize: number }) => void;
  setStoragePageSize: (pageSize: number) => void;
  setFilter: (filter: { pageSize: number }) => void;
  columnVisibility: ColumnVisibility;
  setColumnVisibility: React.Dispatch<React.SetStateAction<ColumnVisibility | undefined>>;
};

export const TopToolbar = ({
  pagination,
  loading,
  totals,
  setPagination,
  setStoragePageSize,
  setFilter,
  columnVisibility,
  setColumnVisibility,
}: PaginationToolbarProps) => {
  const selectPageSizeRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [showExportModal, setShowExportModal] = useState(false);
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);

  useEffect(() => {
    if (!loading) {
      // Remove the focus from the page size select once the data is loaded.
      // This allows the user to use the keyboard to navigate the table.
      selectPageSizeRef.current?.blur();
    }
  }, [loading]);

  useEffect(() => {
    setStoragePageSize(pagination.pageSize);
    // Call setFilter here to update the queryVariables with the new page size
    setFilter({
      pageSize: pagination.pageSize,
    });
  }, [pagination.pageSize]);

  const { pageIndex, pageSize } = pagination;
  const total = totals.count;
  const pageStartNum = pageIndex * pageSize + 1;
  const pageEndNum = Math.min((pageIndex + 1) * pageSize, total);
  const isPrevDisabled = loading || pageIndex === 0;
  const isNextDisabled = loading || pageEndNum >= total;

  const getPageSizeLabel = () => {
    if (loading) {
      return "Loading...";
    }
    const TXN_WORD = "txn";
    if (pageEndNum === 1) {
      return `1 ${TXN_WORD}`;
    }
    if (total <= pageSize) {
      return `${total} ${TXN_WORD}s`; // handles 0 transactions too
    }
    return `${pageStartNum}-${pageEndNum} of ${total} ${TXN_WORD}s`;
  };

  return (
    <div className={containerWrapper} data-testid={DATA_TEST_IDS.TRANSACTIONS_TABLE_TOP_TOOLBAR}>
      <div className={container} ref={containerRef}>
        <Toolbar.Group className={topRow}>
          <FilterToolbar />
        </Toolbar.Group>
        <div className={bottomRow}>
          <div className={bottomRowText}>
            <Totals />
            <label style={{ marginRight: S.$1h }}>{getPageSizeLabel()}</label>
          </div>

          <div className={bottomRowControls}>
            <div className={bottomRowControlsPagination}>
              <Button
                variant="secondaryAlpha"
                size="miniSquare"
                shape="buttonMoreSquare"
                disabled={isPrevDisabled}
                onClick={() => {
                  if (pageIndex === 0) return;
                  setPagination({ ...pagination, pageIndex: pageIndex - 1 });
                }}
              >
                <span className="sr-only">Go to previous page</span>
                <ChevronLeft
                  style={{ display: "flex" }}
                  width={12}
                  height={12}
                  fill={isPrevDisabled ? colors.white300 : colors.white}
                />
              </Button>
              <Button
                variant="secondaryAlpha"
                size="miniSquare"
                shape="buttonMoreSquare"
                disabled={isNextDisabled}
                onClick={() => {
                  if (pageEndNum >= total) return;
                  setPagination({ ...pagination, pageIndex: pageIndex + 1 });
                }}
              >
                <span className="sr-only">Go to next page</span>
                <ChevronRight
                  style={{ display: "flex" }}
                  width={12}
                  height={12}
                  fill={isNextDisabled ? colors.white300 : colors.white}
                />
              </Button>
            </div>
            <div className={bottomRowControlsTable}>
              <Button
                variant="secondaryAlpha"
                size="miniSquare"
                shape="buttonMoreSquare"
                onClick={() => setShowExportModal(true)}
                style={{ display: "flex", alignItems: "center" }}
              >
                <span className="sr-only">Download transactions table</span>
                <Download2 size={14} color={colors.white700} style={{ display: "flex" }} />
              </Button>
              <ColumnVisibilityMenu
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
              />
              <TransactionReportExportModal
                open={showExportModal}
                onConfirm={() => setShowExportModal(false)}
                onCancel={() => setShowExportModal(false)}
                title="Consolidated Transactions"
              />
              <Button
                data-testid="import-transactions-button"
                variant="primary"
                size="miniSquare"
                shape="buttonMoreSquare"
                onClick={() => {
                  setImportModalIsOpen(true);
                  Analytics.transactionImportModalOpened();
                }}
                style={{ display: "flex", alignItems: "center" }}
              >
                <span className="sr-only">Import transactions</span>
                <Add size={16} color={colors.mauve950} style={{ display: "flex" }} />
              </Button>
              <ImportTransactionsModal
                open={importModalIsOpen}
                onOpenChange={setImportModalIsOpen}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
