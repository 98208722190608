import React from "react";
import { IntegrationLogo } from "components/integrations/shared/IntegrationLogo";
import { Bank } from "@puzzle/icons";
import { colors } from "@puzzle/theme";

export const BankLogo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo color={colors.mauve200} circular={circular}>
      <Bank size={18} />
    </IntegrationLogo>
  );
};
