/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import { DateRangePicker, Toolbar, DateRangePickerVariant } from "@puzzle/ui";
import { zIndex } from "@puzzle/utils";

import { useTransactionsPage } from "./TransactionsProvider";
import { ExtendedFilter } from "./ExtendedFilter/ExtendedFilter";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import Analytics from "lib/analytics/analytics";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import { getHighPriorityFilters, isHighPriorityReview } from "lib/transactions/highPriority";
import { topRowFilters, topRowFiltersMain } from "./topToolbar.css";
import { shadows } from "@puzzle/theme";
const SearchBar = () => {
  const { filter, setFilter } = useTransactionsPage();

  return useMemo(
    () => (
      <Toolbar.Search
        placeholder="Search"
        value={filter.descriptor}
        onChange={(descriptor) => setFilter({ descriptor })}
      />
    ),
    [filter.descriptor, setFilter]
  );
};

export const FilterToolbar = React.memo(() => {
  const {
    filter,
    setFilter,
    rangePresets,
    categoriesByPermaKey,
    allTimeRangeCalled,
    fetchAllTimeRange,
  } = useTransactionsPage();
  const { company } = useActiveCompany();

  const dateFilter = useMemo(
    () => (
      <DateRangePicker
        value={[filter.start, filter.end]}
        presets={rangePresets}
        preset={filter.rangePreset ?? undefined}
        onChange={([start, end], rangePreset) => {
          // handle allTime deferred loading
          if (rangePreset?.key === "allTime" && !allTimeRangeCalled) {
            fetchAllTimeRange();
            setFilter({ rangePreset });
            return;
          }

          setFilter({ start, end, rangePreset });
        }}
        customLabelFormat="M/d/yy"
        variant={DateRangePickerVariant.Minimal}
        showInputs
        popoverCss={{
          zIndex: zIndex("menu"),
        }}
      />
    ),
    [filter.end, filter.rangePreset, filter.start, rangePresets, setFilter]
  );

  const isHighPriority = isHighPriorityReview(filter, categoriesByPermaKey);
  return (
    <Toolbar className={topRowFilters}>
      <div className={topRowFiltersMain}>
        {dateFilter}
        <SearchBar />
        <ExtendedFilter
          hasAICategorizationFeatureEnabled={company?.features.assistedCategorizationEnabled}
        />
      </div>
      {!isPosthogFeatureFlagEnabled(FeatureFlag.GroupCategoriesByAccount) && (
        <Toolbar.ToggleButton
          pressed={isHighPriority}
          onPressedChange={(pressed) => {
            Analytics.showHighPriorityReviewClicked();
            setFilter(getHighPriorityFilters(pressed, categoriesByPermaKey));
          }}
          css={{
            borderColor: "$purple700",
            boxShadow: !isHighPriority ? shadows.purple800A02BottomBlurMedium : "none",
          }}
        >
          High Priority Review
        </Toolbar.ToggleButton>
      )}
    </Toolbar>
  );
});
